import React from 'react';
import NavBar from '../NavBar';
import { TransitionPortal } from "gatsby-plugin-transition-link";

const BlogPost = props => {
  return (

    <div className="text-default text-md bg-dark-dots">
      <NavBar/>
      <div className="min-h-screen w-full relative">
          {props.children}
      </div>
    </div>
  );
}

export default BlogPost;
