import React from 'react';
import logo from '../images/logo.svg';
import {Link as ScrollLink} from "react-scroll";
import {Container} from './Grid';
import {Link} from "gatsby";



const NavLink = ({ scroll, anchor, ...props }) => {
    if (scroll) {
        return (
            <ScrollLink

                smooth={true}
                className="cursor-pointer inline-block mt-4 mt-0 hover:text-white mr-6"
                { ...props}
                to={anchor}
                />
        )
    }
    return (
        <Link
            state={{anchor: anchor}}
            smooth={true} offset={-100}
            className="cursor-pointer inline-block mt-4 mt-0 hover:text-white mr-6"
            { ...props }
            />
    )
}

const NavBar = ({
    absolute,
    fixed,
    isHomepage = false
    }) => {

    const styles = {
        position: absolute
            ? 'absolute'
            : fixed
                ? 'fixed'
                : 'relative',
        zIndex: 1000,
        mixBlendMode: `difference`
    };

    return (
        <nav id="nav" className="w-full" style={styles}>
            <Container>
                <div className="w-full flex items-center justify-between flex-wrap py-8 text-lg">
                    <div className="flex items-center flex-shrink-0 text-white mr-6">
                        <NavLink to="/" anchor="nav" scroll={isHomepage}>
                            <img alt="KBiel" src={logo} className="fill-currenth-10 mr-2 h-10"/>
                        </NavLink>
                    </div>
                    <div className="block text-right font-bold text-sm md:hidden">
                        <Link className="inline-block mt-4 mt-0 hover:text-white" href="/blog" to="/blog">
                            BLOG
                        </Link>
                    </div>
                    <div className="hidden text-right w-full md:block flex-grow md:flex md:items-center md:w-auto">
                        <div className="text-sm font-bold uppercase tracking-widest md:flex-grow">
                            <a href="/blog" className="inline-block mt-4 mt-0 hover:text-white mr-6">
                                BLOG
                            </a>
                            <NavLink to="/" anchor="about" scroll={isHomepage}>
                                ABOUT
                            </NavLink>
                            <NavLink offset={-100} to="/" anchor="skills" scroll={isHomepage}>
                                SKILLS
                            </NavLink>
                            <NavLink to="/" anchor="contact" scroll={isHomepage}>
                                CONTACT
                            </NavLink>

                        </div>
                    </div>
                </div>
            </Container>
        </nav>);
}

export default NavBar;
