import React from 'react';
import Image from 'gatsby-image';

const BlogImage = ({image, ...other}) => {
  if (!image) {
    return (<img alt="" {...other}/>)
  }

  if (!image.childImageSharp && image.extension === 'svg') {
    return (<img alt="" src={image.publicURL} {...other}/>)
  }

  return (<Image fluid={image.childImageSharp.fluid} {...other} alt=""/>);
}

export default BlogImage;
