import React, { useEffect, useState, useRef } from 'react';

const Reveal = props => {
    const {
            className,
            children,
            duration=500,
            delay=100,
            offset=0,
            bgClass="bg-body",
        } = props;

    const [visible, setVisible] = useState(false);
    const revealElement = useRef(null);


    const isVisible = () => {
        const elPosition = revealElement.current.getBoundingClientRect();
        return window.innerHeight && (elPosition.top < window.innerHeight - offset);
    }

    const handleScroll = () => {
        const inViewport = isVisible();
        if (inViewport === true) {
            setVisible(true);
            window.removeEventListener('scroll', handleScroll);
        }
    }

    const getAnimation = visible => {
        return visible ? 'translateX(101%)' : 'translateX(0%)';
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [revealElement]);

    useEffect(() => {
        handleScroll();
    }, []);

    return (
        <div className={ className }>
            <div ref={revealElement} className="relative inline-block overflow-x-hidden">
                <div
                    className={ "block absolute " + bgClass }
                    style={{
                        height: `100%`,
                        width: `100%`,
                        transition: `${duration/1000}s all ease-in-out`,
                        transitionDelay: `${delay/1000}s`,
                        transform: getAnimation(visible),
                        WebkitTransform: getAnimation(visible),
                        zIndex: 1,
                    }}
                />
                {children}
            </div>
        </div>
    );
}

export default Reveal;
