import React from 'react';
import BlogImage from './BlogImage';
import Zoom from 'react-reveal/Zoom';

const BlogPost = props => {
  const { title, description, path, date, image, animated=false} = props;
  return (
    <div {...props}>
        <div>
            <div className="py-4">
                { image && (
                    <div className="bg-gray-300 h-40">
                        { animated ? (
                            <Zoom offset="200px" delay={100} duration={1000}>
                                <BlogImage className="w-full mb-2 h-40 object-cover" image={image}/>
                            </Zoom>
                        ) : (
                            <BlogImage className="w-full mb-2 h-40 object-cover" image={image}/>
                        )}

                    </div>
                ) }

                <div className="pt-4">
                    <p className="text-xs mb-2">{date}</p>
                    <p className="text-md font-bold leading-tight max-w-md mb-2"> {title} </p>
                    <p className="text-sm leading-tight max-w-md mb-2 opacity-50"> {description} </p>
                    <a href={path} className="uppercase text-xs tracking-widest font-bold">
                        Read more
                        <span className="fira text-brand"> ▸</span>
                    </a>
                </div>

            </div>




        </div>
    </div>
  );
}

export default BlogPost;
