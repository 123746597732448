import React from 'react';

const Dashed = props => {
  const { children, ...other } = props;
  return (
    <div  className="my-12" {...other}>
      <div className="pl-4 border-l-4 border-brand">
        { children }
      </div>
    </div>
  );
}

export default Dashed;
