import React from 'react';

const Grid = ({className, children}) => {

    return (
        <div className={className}>
            <div className="relative flex flex-wrap -mx-4">
                { children }
            </div>
        </div>
    );
}

const Item = ({ children, xs, sm, md, lg="1/2", xl }) => {
    const breakpoints = {
        xs: xs,
        sm: sm,
        md: md,
        lg: lg,
        xl: xl,
    }

    const classes = Object.keys(breakpoints).map( key => {
        const width = breakpoints[key];
        if (width) {return `${key}:w-${width}`;}
    });

    return (
        <div className={"my-4 px-4 w-full " + classes.join(' ')}>
            { children }
        </div>
    )
}

const Container = ({ ...props }) => {
    return (
        <div className="w-full max-w-5xl mx-auto px-10 md:px-20 lg:px-10" {...props} />
    )
}


export default Grid;

export {
    Grid,
    Item,
    Container
}
